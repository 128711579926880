/* eslint noconsole:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require("@gouvfr/dsfr/dist/js/dsfr.module.js")
import flatpickr from 'flatpickr'
import { French } from 'flatpickr/dist/l10n/fr'

function importAll(r) {
  return r.keys().map(r)
}

// HACK : VTA/GITLAB#67
document.addEventListener("turbo:render", () => {
  dsfr.Collapse.build(document);

})

importAll(require.context('images/', true, /\.(ico|png|jpe?g|svg|gif)$/))
importAll(require.context('icons/', true, /\.svg$/))


import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()
const context = require.context("../controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

document.addEventListener('turbo:load', function () {
  flatpickr('.flatpickr_date', {
    locale: French,
    altInput: true,
    altFormat: 'd/m/Y',
    dateFormat: 'Y-m-d'
  })
})
